import Image from "next/image";
import { cn } from "@/lib/utils";

const FeaturesBento = () => (
  <div className="px-6">
    <div
      className={cn(
        "mx-auto w-full rounded-2xl border-gray-100 p-2 @lg:border lg:max-w-screen-lg xl:max-w-screen-xl 2xl:max-w-screen-2xl",
        // "border-4 @xs:border-green-600 @sm:border-blue-600 @md:border-red-500 @lg:border-blue-500 @xl:border-green-500",
        // "@2xl:border-yellow-500 @3xl:border-purple-500 @4xl:border-pink-200 @5xl:border-orange-500 @6xl:border-teal-500 @7xl:border-lime-500",
      )}
    >
      <div
        className={cn(
          "grid grid-cols-1 gap-4 rounded-2xl border-gray-100 @lg:border @lg:bg-[#FBFBFB] @2xl:grid-cols-3 @2xl:grid-rows-6",
          "@2xl:h-[670px] @4xl:h-[820px] @6xl:h-[800px]",
          "@md:p-4 @2xl:p-0 @4xl:p-4",
        )}
      >
        {/* bento col 1 */}
        <div className="relative overflow-hidden px-4 @2xl:col-start-1 @2xl:row-span-3">
          <div className="absolute inset-px rounded-xl bg-[#F7F8FB]" />
          <div className="relative flex h-full flex-col space-y-7 overflow-hidden pt-4">
            <div className="space-y-2">
              <h3 className="leading- text-2xl font-medium leading-[1.2em] tracking-tight text-[#000D49] @2xl:text-xl @3xl:text-2xl">
                Fake Texts Videos
              </h3>
              <p className="mt-1 max-w-lg text-sm leading-5 text-[#8F95A1]">
                Have an idea for a convo that would go viral? Make it into a full video in just a
                few clicks.
              </p>
            </div>
            <div className="flex max-h-80 w-full justify-center @2xl:translate-y-4">
              <Image
                alt="fake text videos"
                src="https://cdn-crayo.com/lp/public/landing/bento/faketexts.png"
                className="w-full @md:max-w-96"
                width={0}
                height={0}
                draggable={false}
              />
            </div>
          </div>
          <div className="pointer-events-none absolute inset-px rounded-xl shadow ring-1 ring-black/5" />
        </div>
        <div className="relative px-4 @2xl:col-start-1 @2xl:row-span-3 @2xl:row-start-4">
          <div className="absolute inset-px rounded-xl bg-gradient-to-b from-[#FAF9F9] via-[#FAF9F9] to-[#F3EFED]" />
          <div className="relative flex h-full flex-col overflow-hidden pb-4 pt-4">
            <div className="space-y-2">
              <h3 className="text-2xl font-medium leading-[1.2em] tracking-tight text-[#000D49] @2xl:text-xl @3xl:text-2xl">
                Create Split-Screen Videos
              </h3>
              <p className="mt-1 max-w-xs text-sm leading-5 text-[#8F95A1]">
                Make your clips more engaging by showing them beside premium gameplay.
              </p>
            </div>
            <div className="flex max-h-80 w-full items-center justify-center @md:px-3 @2xl:translate-y-4 @7xl:-translate-y-6">
              <Image
                alt="split screen story"
                src="https://cdn-crayo.com/lp/public/landing/bento/splitscreenstory.png"
                className="-mb-10 w-[120%] max-w-[27rem]"
                width={0}
                height={0}
                draggable={false}
              />
            </div>
          </div>
          <div className="pointer-events-none absolute inset-px rounded-xl shadow ring-1 ring-black/5" />
        </div>

        {/* bento col 2 */}
        <div className="relative px-4 @2xl:col-start-2 @2xl:row-span-2">
          <div className="absolute inset-px rounded-xl bg-gradient-to-b from-[#F6F6F9] to-[#F2F0F8]" />
          <div className="relative flex h-full flex-col overflow-hidden pt-4">
            <div className="space-y-2">
              <h3 className="text-2xl font-medium leading-[1.2em] tracking-tight text-[#000D49] @2xl:text-xl @3xl:text-2xl">
                Generate AI Voiceovers
              </h3>
              <p className="mt-1 max-w-lg text-sm leading-5 text-[#8F95A1]">
                It&apos;s never been easier to make the AI-narrated videos you see on your timeline.
              </p>
            </div>
            <div className="flex max-h-80 w-full items-center justify-center py-6 @2xl:pt-3.5 @6xl:pt-6">
              <Image
                alt="voiceover"
                src="https://cdn-crayo.com/lp/public/landing/bento/voiceover.svg"
                className="w-full"
                width={0}
                height={0}
                draggable={false}
              />
            </div>
          </div>
          <div className="pointer-events-none absolute inset-px rounded-xl shadow ring-1 ring-black/5" />
        </div>
        <div className="relative px-4 @2xl:col-start-2 @2xl:row-span-2">
          <div className="absolute inset-px rounded-xl bg-[#F3F4F8]" />
          <div className="relative flex h-full flex-col space-y-5 overflow-hidden pb-4 pt-4">
            <div className="space-y-2">
              <h3 className="text-2xl font-medium leading-[1.2em] tracking-tight text-[#000D49] @2xl:text-xl @3xl:text-2xl">
                Text-to-Image Videos
              </h3>
              <p className="mt-1 max-w-lg text-sm leading-5 text-[#8F95A1]">
                Generate images for your videos with text prompts.
              </p>
            </div>
            <div className="flex max-h-80 w-full items-center justify-center @3xl:-translate-y-3 @6xl:pt-3 @7xl:pt-4">
              <Image
                alt="text to image"
                src="https://cdn-crayo.com/lp/public/landing/bento/text2img.png"
                className="w-full"
                width={0}
                height={0}
                draggable={false}
              />
            </div>
          </div>
          <div className="pointer-events-none absolute inset-px rounded-xl shadow ring-1 ring-black/5" />
        </div>
        <div className="relative px-4 @2xl:col-start-2 @2xl:row-span-2">
          <div className="absolute inset-px rounded-xl bg-gradient-to-b from-[#FAFAFA] to-[#F6F7FB]" />
          <div className="relative flex h-full flex-col space-y-5 overflow-hidden pt-4">
            <div className="space-y-2">
              <h3 className="text-2xl font-medium leading-[1.2em] tracking-tight text-[#000D49] @2xl:text-xl @3xl:text-2xl">
                Download TikTok & Youtube Videos
              </h3>
              <p className="mt-1 max-w-lg text-sm leading-5 text-[#8F95A1]">
                Avoid sketchy sites and get content for your next video.
              </p>
            </div>
            <div className="flex max-h-80 w-full items-center justify-center @2xl:translate-y-2.5 @3xl:-translate-y-1.5 @6xl:translate-y-6 @7xl:-translate-y-1">
              <Image
                alt="downloader"
                src="https://cdn-crayo.com/lp/public/landing/bento/downloader.png"
                className="w-full"
                width={0}
                height={0}
                draggable={false}
              />
            </div>
          </div>
          <div className="pointer-events-none absolute inset-px rounded-xl shadow ring-1 ring-black/5 " />
        </div>

        {/* bento col 3 */}
        <div className="relative px-4 @2xl:col-start-3 @2xl:row-span-3 @2xl:row-start-1">
          <div className="absolute inset-px rounded-xl bg-gradient-to-b from-[#FAFAFA] to-[#F7EFF6]/80" />
          <div className="relative flex h-full flex-col space-y-5 overflow-hidden pt-4">
            <div className="space-y-2">
              <h3 className="text-2xl font-medium leading-[1.2em] tracking-tight text-[#000D49] @2xl:text-xl @3xl:text-2xl">
                Create Reddit Story Videos
              </h3>
              <p className="mt-1 max-w-sm text-pretty text-sm leading-5 text-[#8F95A1]">
                Write your own script or generate one auto-magically from a Reddit link.
              </p>
            </div>
            <div className="flex max-h-80 w-full items-center justify-center @6xl:translate-y-7 @7xl:translate-y-0">
              <Image
                alt="story video"
                src="https://cdn-crayo.com/lp/public/landing/bento/storyvideo.png"
                className="w-full max-w-80"
                width={0}
                height={0}
                draggable={false}
              />
            </div>
          </div>
          <div className="pointer-events-none absolute inset-px rounded-xl shadow ring-1 ring-black/5  " />
        </div>
        <div className="relative px-4 @2xl:col-start-3 @2xl:row-span-3 @2xl:row-start-4">
          <div className="absolute inset-px rounded-xl bg-gradient-to-b from-[#FAFAFA] to-[#F6F7FB]" />
          <div className="relative flex h-full flex-col space-y-2 overflow-hidden pb-6 pt-4">
            <div className="space-y-2">
              <h3 className="text-2xl font-medium leading-[1.2em] tracking-tight text-[#000D49] @2xl:text-xl @3xl:text-2xl">
                Voiceover Story
              </h3>
              <p className="mt-1 max-w-xs text-sm leading-5 text-[#8F95A1]">
                Choose from hundreds of high-quality AI voices to bring your content to life.
              </p>
            </div>
            <div className="flex max-h-80 w-full translate-y-3 items-center justify-center @2xl:translate-y-6 @5xl:translate-y-8 @6xl:translate-y-5 @7xl:translate-y-1">
              <Image
                alt="voiceover story"
                src="https://cdn-crayo.com/lp/public/landing/bento/voiceoverstory.png"
                className="w-full max-w-80"
                width={0}
                height={0}
                draggable={false}
              />
            </div>
          </div>
          <div className="pointer-events-none absolute inset-px rounded-xl shadow ring-1 ring-black/5" />
        </div>
      </div>
    </div>
  </div>
);

export default FeaturesBento;
