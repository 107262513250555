import Image from "next/image";
import { cn } from "@/lib/utils";

const theme = {
  colors: {
    darkBorder: "border-gray-700",
    lightBorder: "border-gray-200",
    darkBg: "bg-[#101010]",
    lightBg: "bg-white",
    darkText: "text-white",
    lightText: "text-black",
    iconBlue: "#19A5FC",
    darkIcon: "text-[#DCDCDC]",
    lightIcon: "text-[#adadad]",
  },
};

type SocialCardProps = {
  userAvatar: string;
  userName: string;
  content: string;
  darkMode: boolean;
};

export const RedditSocialCard = ({ userAvatar, userName, content, darkMode }: SocialCardProps) => {
  return (
    <div className="flex w-full flex-row items-center space-x-1">
      <div
        id="social-card"
        className={cn(
          "w-full select-none overflow-hidden rounded-xl border p-4",
          darkMode ? theme.colors.darkBorder : theme.colors.lightBorder,
          darkMode ? theme.colors.darkBg : theme.colors.lightBg,
        )}
      >
        <div className="flex flex-col">
          <div className="flex items-center">
            <div className="-mt-2 flex items-center justify-center overflow-hidden rounded-full">
              <Image
                alt="reddit pfp"
                className="size-9 rounded-full object-cover"
                width={10}
                height={10}
                src={userAvatar}
                draggable={false}
              />
            </div>
            <div className="-mt-6 ml-2 flex items-center">
              <span
                className={cn(
                  "font-semibold",
                  darkMode ? theme.colors.darkText : theme.colors.lightText,
                )}
              >
                {userName}
              </span>
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 24 24"
                color={theme.colors.iconBlue}
                className={cn("ml-1", darkMode ? theme.colors.darkIcon : theme.colors.lightIcon)}
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10.007 2.10377C8.60544 1.65006 7.08181 2.28116 6.41156 3.59306L5.60578 5.17023C5.51004 5.35763 5.35763 5.51004 5.17023 5.60578L3.59306 6.41156C2.28116 7.08181 1.65006 8.60544 2.10377 10.007L2.64923 11.692C2.71404 11.8922 2.71404 12.1078 2.64923 12.308L2.10377 13.993C1.65006 15.3946 2.28116 16.9182 3.59306 17.5885L5.17023 18.3942C5.35763 18.49 5.51004 18.6424 5.60578 18.8298L6.41156 20.407C7.08181 21.7189 8.60544 22.35 10.007 21.8963L11.692 21.3508C11.8922 21.286 12.1078 21.286 12.308 21.3508L13.993 21.8963C15.3946 22.35 16.9182 21.7189 17.5885 20.407L18.3942 18.8298C18.49 18.6424 18.6424 18.49 18.8298 18.3942L20.407 17.5885C21.7189 16.9182 22.35 15.3946 21.8963 13.993L21.3508 12.308C21.286 12.1078 21.286 11.8922 21.3508 11.692L21.8963 10.007C22.35 8.60544 21.7189 7.08181 20.407 6.41156L18.8298 5.60578C18.6424 5.51004 18.49 5.35763 18.3942 5.17023L17.5885 3.59306C16.9182 2.28116 15.3946 1.65006 13.993 2.10377L12.308 2.64923C12.1078 2.71403 11.8922 2.71404 11.692 2.64923L10.007 2.10377ZM6.75977 11.7573L8.17399 10.343L11.0024 13.1715L16.6593 7.51465L18.0735 8.92886L11.0024 15.9999L6.75977 11.7573Z"></path>
              </svg>
            </div>
          </div>
          <div className="relative -mt-4 ml-[40px] max-w-[180px]">
            <Image
              alt="AWARDS"
              className="w-full"
              width={10}
              height={10}
              draggable={false}
              src="https://crayo.ai/assets/editor/reddit_awards.png"
            />
          </div>
        </div>
        <div className="mb-2 mt-2 text-left">
          <p className={darkMode ? theme.colors.darkText : theme.colors.lightText}>{content}</p>
        </div>
        <div className="flex justify-between">
          <div className="-mb-2 flex items-center space-x-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke={darkMode ? "#DCDCDC" : "#adadad"}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-heart scale-x-[-1] transform"
            >
              <path d="M19 14c1.49-1.46 3-3.21 3-5.5A5.5 5.5 0 0 0 16.5 3c-1.76 0-3 .5-4.5 2-1.5-1.5-2.74-2-4.5-2A5.5 5.5 0 0 0 2 8.5c0 2.3 1.5 4.05 3 5.5l7 7Z"></path>
            </svg>
            <span
              className={cn(
                "ml-1 text-sm font-normal",
                darkMode ? theme.colors.darkIcon : theme.colors.lightIcon,
              )}
            >
              99+
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke={darkMode ? "#DCDCDC" : "#adadad"}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-message-circle scale-x-[-1] transform"
            >
              <path d="M7.9 20A9 9 0 1 0 4 16.1L2 22Z"></path>
            </svg>
            <span
              className={cn(
                "ml-1 mt-[0.2px] text-sm font-normal",
                darkMode ? theme.colors.darkIcon : theme.colors.lightIcon,
              )}
            >
              99+
            </span>
          </div>
          <div className="-mb-2 flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke={darkMode ? "#DCDCDC" : "#adadad"}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-share"
            >
              <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path>
              <polyline points="16 6 12 2 8 6"></polyline>
              <line x1="12" x2="12" y1="2" y2="15"></line>
            </svg>
            <span
              className={cn(
                "ml-1 text-sm font-normal",
                darkMode ? theme.colors.darkIcon : theme.colors.lightIcon,
              )}
            >
              share
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
