import { RxStarFilled } from "react-icons/rx";

export const StarRatings = () => (
  <div className="flex justify-center">
    <div className="relative flex items-center gap-x-2 rounded-full bg-[#F5F6FB] px-2 py-1.5 text-sm/6 text-gray-600">
      <div className="flex items-center justify-center">
        {Array(5)
          .fill(null)
          .map((_, index) => (
            <RxStarFilled key={index} className="size-3 text-[#eab308] @md:size-5" />
          ))}
      </div>
      <span className="text-sm font-normal text-[#0A0D14]">
        <span className="font-medium">2.1M+</span>
        <span className="font-medium md:hidden"> users</span>
        <span className="hidden md:inline"> users worldwide</span>
      </span>
    </div>
  </div>
);
