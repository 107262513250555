import { RedditStoryEditor } from "./RedditStoryEditor";

const RedditStorySection = () => (
  <div className="pt-28 @container">
    <div className="mx-auto max-w-screen-xl space-y-6 px-6 @lg:space-y-10 lg:px-8">
      <div className="mx-auto flex w-full flex-col items-center space-y-3 sm:text-center lg:max-w-screen-xl">
        <h3 className="text-pretty text-center text-[48px] font-semibold leading-[1.05] text-[#101828]">
          Keep up with trends and <span className="text-[#335CFF]">Go Viral</span>
        </h3>
        <p className="text-pretty text-center text-[20px] tracking-wide text-[#525866]">
          From brainstorming to publishing, Crayo supports
          <br className="hidden @3xl:inline" />
          &nbsp;all your short-form content needs.
        </p>
      </div>
      <div className="m-auto overflow-hidden @5xl:w-[95%]">
        <RedditStoryEditor />
      </div>
      <div className="flex items-center justify-center gap-x-6">
        <a
          href="https://crayo.ai/dashboard"
          className="rounded-full bg-[#5073FF] px-[18px] py-4 text-[16px] tracking-wide text-white shadow-sm transition-colors hover:bg-[#5073FF]/80"
        >
          Try Crayo Now
        </a>
      </div>
    </div>
  </div>
);

export default RedditStorySection;
