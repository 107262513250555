import { NextSeo } from "next-seo";
import dynamic from "next/dynamic";
import { HeroButton } from "@/components/home/HeroButton";
import { StarRatings } from "@/components/home/StarRatings";
import Testimonials from "@/components/home/Testimonials";
import FeaturesBento from "@/components/home/FeaturesBento";
import RedditStorySection from "@/components/home/RedditStorySection";
import StepsSection from "@/components/home/StepsSection";
import { HeroSection } from "@/components/shared/HeroSection";
import FAQ from "@/components/shared/FAQ";
import Footer from "@/components/shared/Footer";

const CarouselSection = dynamic(() => import("@/components/home/CarouselSection"), { ssr: false });

const Home = () => (
  <div className="space-y-3 overflow-hidden bg-white transition-all duration-200 ease-in-out lg:space-y-20 lg:pt-12">
    <NextSeo
      title="Crayo AI | Viral clips in seconds."
      description="Your gateway to creating viral TikTok clips in seconds! Harness the power of AI to turn your ideas into captivating videos effortlessly."
    />

    <HeroSection
      topComponent={<StarRatings />}
      heading={
        <>
          Generate <br className="inline @5xl:hidden" />
          viral-ready clips
          <br /> in seconds
        </>
      }
      body="Your all-in-one tool for creating AI voiceovers, engaging subtitles optimized gameplay, and more."
      button={<HeroButton />}
    >
      <FeaturesBento />
    </HeroSection>

    <div className="space-y-32">
      <RedditStorySection />
      <CarouselSection />
      <StepsSection />
      <Testimonials />
      <FAQ />
      <Footer />
    </div>
  </div>
);

export default Home;
