import { useEffect, useState } from "react";
import Image from "next/image";
import { cn } from "@/lib/utils";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

const steps = [
  {
    title: "Write and outline",
    description: "Start with writing script using a prompt or using template suggestions.",
    image: "https://cdn-crayo.com/lp/public/landing/steps/step1.png",
  },
  {
    title: "Customize and style",
    description: "Select background video, voice and a music from free templates.",
    image: "https://cdn-crayo.com/lp/public/landing/steps/step2.png",
  },
  {
    title: "Finish and export",
    description: "Choose export format and download generated videos, script or narration.",
    image: "https://cdn-crayo.com/lp/public/landing/steps/step3.png",
  },
];

const MobileTabSteps = () => (
  <Tabs defaultValue="step-1" className="mx-auto block w-full max-w-3xl space-y-5 p-4 xl:hidden">
    <TabsList className="mx-auto grid h-full w-[95%] grid-cols-3">
      {steps.map((_, index) => (
        <TabsTrigger key={index} value={`step-${index + 1}`}>
          STEP {index + 1}
        </TabsTrigger>
      ))}
    </TabsList>
    {steps.map((step, index) => (
      <TabsContent key={index} value={`step-${index + 1}`}>
        <div
          className={cn(
            "flex w-full flex-col justify-between overflow-hidden rounded-xl border border-gray-200 bg-[#FAFAF9] px-4 pt-5 @sm:px-5 @sm:pt-8",
            "h-[430px] @xs:h-[460px] @sm:h-[494px] @md:h-[570px] @lg:h-[630px] @xl:h-[730px] @2xl:h-[824px]",
          )}
        >
          <div className="w-full space-y-2.5 px-1">
            <p className="text-2xl font-semibold tracking-wide">{step.title}</p>
            <p className="max-w-lg text-[16px] tracking-wide text-[#525866] @3xl:max-w-2xl">
              {step.description}
            </p>
          </div>
          <div className="max-w-11/12 m-auto w-full overflow-hidden rounded-2xl shadow-sm">
            <Image
              alt="product demo"
              src={step.image}
              className="w-full"
              width={0}
              height={0}
              draggable={false}
            />
          </div>
        </div>
      </TabsContent>
    ))}
  </Tabs>
);

const AnimatedSteps = () => {
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveStep((prev) => (prev + 1) % steps.length);
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="hidden h-[580px] w-full justify-center px-6 @6xl:h-[620px] xl:block">
      <div className="mx-auto flex h-full w-full max-w-[1200px] justify-between space-x-5 rounded-xl border border-gray-200 bg-[#FAFAF9] p-4">
        <div className="flex h-full w-1/2 flex-col overflow-hidden rounded-xl border border-gray-200 transition-all duration-300 ease-in-out">
          {steps.map((step, index) => (
            <>
              <div
                key={index}
                onClick={() => setActiveStep(index)}
                className={cn(
                  "relative isolate flex h-72 w-full flex-col space-y-2.5 px-6 pb-5 pt-6",
                  index === activeStep ? "bg-white" : "bg-[#FAFAF9]",
                  index === 0 && "rounded-t-xl",
                  index === 1 && "border-y border-gray-200",
                  index === steps.length - 1 && "rounded-b-xl",
                )}
              >
                <p className="text-sm tracking-wide text-[#3175FF]">STEP {index + 1}</p>
                <p className="text-[20px] font-semibold tracking-wide">{step.title}</p>
                <p className="max-w-lg text-[16px] tracking-wide text-[#525866]">
                  {step.description}
                </p>
              </div>
              <div
                className={cn(
                  "h-0.5 w-full animate-marquee bg-[#3175FF]",
                  activeStep === 2 && "w-[98%]",
                  index !== activeStep && "hidden",
                )}
                style={
                  {
                    "--duration": "10s",
                    "--gap": "16px",
                    animationDirection: "reverse",
                  } as React.CSSProperties
                }
              />
            </>
          ))}
        </div>
        <div className="m-auto w-[56.5%] overflow-hidden rounded-2xl shadow-sm">
          <Image
            alt="product demo"
            src={steps[activeStep].image}
            className="w-full rounded-2xl"
            width={0}
            height={0}
            draggable={false}
          />
        </div>
      </div>
    </div>
  );
};

const StepsSection = () => (
  <div className="@container">
    <div className="space-y-10 xl:space-y-12">
      <div className="mx-auto px-6">
        <div className="mx-auto space-y-2 text-center">
          <p className="mx-auto text-pretty text-center text-[54px] font-semibold leading-[1.05] text-[#101828]">
            Create viral clips in <span className="text-[#335CFF]">3 simple steps</span>
          </p>
          <p className="mx-auto max-w-2xl text-pretty text-center text-[20px] tracking-wide text-[#525866]">
            Never manually stitch a clip or generate subtitles by hand ever again.
          </p>
        </div>
      </div>
      <MobileTabSteps />
      <AnimatedSteps />
    </div>
  </div>
);

export default StepsSection;
