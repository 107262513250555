import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { MinusCircle, PlusCircle } from "lucide-react";
import Link from "next/link";

const FAQ_DATA = [
  {
    question: "Can I cancel my plan?",
    answer:
      "Yes. You can cancel your plan anytime via settings. Your plan will remain active until the end of the billing cycle.",
  },
  {
    question: "How do I view how many credits I have left?",
    answer:
      "You can view your usage in settings by clicking your profile picture in the top right corner.",
  },
  {
    question: "Can I change my plan after I subscribe?",
    answer: "Yes. You can upgrade or downgrade your plan at any time by visiting account settings.",
  },
  {
    question: "Do you have a refund policy?",
    answer:
      "Unfortunately, we do not offer refunds. You can cancel your plan anytime and your plan will remain active until the end of the billing cycle.",
  },
  {
    question: "What is an export minute?",
    answer:
      "An export minute is the length of video you can generate with Crayo. For example, if you have 60 export minutes, you can generate a 1 minute video 60 times.",
  },
  {
    question: "What is an AI video credit?",
    answer:
      "An AI video is a video auto-edited by Crayo’s AI. A credit is used each time you generate an auto-edited video.",
  },
  {
    question: "Can I monetize videos created with Crayo?",
    answer:
      "Yes. You fully own the rights to all videos. We use custom recorded gameplay to ensure originality.",
  },
  {
    question: "Can I generate in other languages?",
    answer:
      "Yes. We support all languages. Simply provide the text in the language you want to generate in.",
  },
  {
    question: "Can I import my own content into Crayo?",
    answer: "Yes. You can import your own content during the AI workflow or while in the editor.",
  },
] as const;

const FAQ = () => (
  <div className="flex w-full flex-col items-center justify-center space-y-6 px-6 @container md:space-y-10">
    <div className="w-full max-w-[90%] space-y-14 @6xl:max-w-6xl">
      <div className="space-y-7">
        <h2 className="text-center text-3xl font-semibold md:text-5xl">
          Frequently asked questions
        </h2>
        <p className="text-center text-[20px] leading-tight text-[#525866]">
          Everything you need to know about the product and billing.
        </p>
      </div>
      <Accordion type="single" collapsible className="mx-auto w-full sm:w-3/4">
        {FAQ_DATA.map((item, index: number) => (
          <AccordionItem
            key={index}
            value={`item-${index}`}
            className={index === FAQ_DATA.length - 1 ? "border-b-0" : undefined}
          >
            <AccordionTrigger className="text-[12px] font-semibold tracking-wide text-[#101828] md:text-base">
              {item.question}
              <PlusCircle
                className="mb-1 mr-2 size-5 shrink-0 text-[#3F65FF] transition-transform duration-200 group-data-[state=open]:hidden"
                strokeWidth={2.3}
              />
              <MinusCircle
                className="mb-1 mr-2 size-5 shrink-0 text-[#3F65FF] transition-transform duration-200 group-data-[state=closed]:hidden"
                strokeWidth={2.3}
              />
            </AccordionTrigger>
            <AccordionContent className="max-w-[90%] text-[16px] leading-normal text-[#525866]">
              {item.answer}
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
    <div className="mx-auto flex w-full max-w-[95%] flex-col items-center justify-center space-y-8 rounded-2xl bg-[#F9FAFB] px-8 py-8 text-center @7xl:max-w-[1200px]">
      <div className="flex flex-col items-center justify-center space-y-3">
        <h3 className="text-[25px] font-medium text-[#101828]">Still have questions?</h3>
        <p className="text-lg tracking-wide text-[#525866]">
          Can&apos;t find the answer you&apos;re looking for? Please chat to our friendly team.
        </p>
      </div>
      <Link
        href="mailto:support@crayo.ai"
        className="rounded-full bg-[#345DFF] px-6 py-4 text-[14px] font-medium tracking-wide text-white transition-opacity hover:opacity-90"
      >
        Get in touch
      </Link>
    </div>
  </div>
);

export default FAQ;
