import { type ReactNode } from "react";
import Image from "next/image";
import { cn } from "@/lib/utils";

type HeroSectionProps = {
  heading: string | ReactNode;
  body?: string | ReactNode;
  button?: ReactNode;
  topComponent?: ReactNode;
  children?: ReactNode;
};

export const HeroSection = (props: HeroSectionProps) => (
  <div className="relative isolate @container">
    {/* pixels bg */}
    <div
      className={cn(
        "relative inset-0 top-6 -z-10 mx-auto hidden w-full md:block xl:max-w-[1800px]",
        props.topComponent && "top-52 md:top-20",
      )}
    >
      <div className="absolute -left-10 w-full max-w-[20vw] lg:max-w-[17vw] xl:max-w-[200px]">
        <Image
          alt="pixels"
          src="https://cdn-crayo.com/lp/public/landing/pixel1.svg"
          className="w-full"
          width={0}
          height={0}
          draggable={false}
        />
      </div>
      <div className="absolute -right-16 w-full max-w-[26vw] lg:max-w-[20vw] xl:max-w-[250px]">
        <Image
          alt="pixels"
          src="https://cdn-crayo.com/lp/public/landing/pixel2.svg"
          className="w-full"
          width={0}
          height={0}
          draggable={false}
        />
      </div>
    </div>

    <div className="mx-auto max-w-2xl space-y-6 pt-28 @sm:pt-32 @4xl:max-w-6xl @4xl:space-y-8 @4xl:pt-16 md:pb-4 md:pt-8">
      <div className="flex flex-col items-center space-y-5 lg:space-y-6">
        {props.topComponent ?? null}
        <h1 className="text-center text-5xl font-semibold text-gray-900 @2xl:text-7xl lg:text-balance lg:text-6xl lg:tracking-[0.015em]">
          {props.heading}
        </h1>
        {!props?.body ? null : typeof props.body === "string" ? (
          <p className="text-balance text-center text-lg text-[#868C98]/90 @md:max-w-xl lg:text-lg">
            {props.body}
          </p>
        ) : (
          props.body
        )}
      </div>
      {props.button ?? null}
    </div>

    {props.children ?? null}
  </div>
);
