export const HeroButton = () => (
  <div className="flex items-center justify-center gap-x-6">
    <a
      href="https://crayo.ai/dashboard"
      className="overflow-hidden rounded-full border-[6px] border-[#DBE5FF] bg-[#3870FF] px-8 py-5 shadow-sm transition-colors hover:bg-[#3870FF]/80 md:px-[37px] md:py-[23px]"
    >
      <div className="relative -left-8 top-0 isolate scale-y-[0.4]">
        <svg viewBox="0 0 1024 1024" aria-hidden="true" className="absolute z-10 size-[15rem]">
          <circle
            r={512}
            cx={512}
            cy={512}
            fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
            fillOpacity="0.7"
          />
          <defs>
            <radialGradient
              r={1}
              cx={0}
              cy={0}
              id="759c1415-0410-454c-8f7c-9a820de03641"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(512 512) rotate(90) scale(512)"
            >
              <stop stopColor="#91F4FF" />
              <stop offset={1} stopColor="#91F4FF" stopOpacity={0} />
            </radialGradient>
          </defs>
        </svg>
      </div>
      <span className="relative z-10 text-lg font-medium tracking-wide text-white md:text-[26px]">
        Try Crayo Now
      </span>
    </a>
  </div>
);
