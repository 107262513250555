import Image from "next/image";
import { cn } from "@/lib/utils";

const testimonials = [
  {
    name: "Musa Mustafa",
    avatar: "https://cdn-crayo.com/lp/public/landing/testimonials/musa.png",
    text: "I've clipped for some of the biggest creators on the internet. Crayo is the tool I wish I had when I started.",
  },
  {
    name: "Jonathan D.",
    avatar: "https://cdn-crayo.com/lp/public/landing/testimonials/olivia.png",
    text: "I was skeptical at first, but wow, this thing really works. Easy to use, fast results, and it’s just super reliable. Highly recommend!",
  },
  {
    name: "Daniel Bitton",
    avatar: "https://cdn-crayo.com/lp/public/landing/testimonials/daniel.png",
    text: "After running channels with over 1 million subscribers, I built Crayo to solve my own scaling problems.",
  },
  {
    name: "Dave W.",
    avatar: "https://cdn-crayo.com/lp/public/landing/testimonials/jake.png",
    text: "I’m honestly impressed! Crayo does everything I needed and more. It's probably the best investment I've made for growing my channels.",
  },
  {
    name: "Brandon M.",
    avatar: "https://cdn-crayo.com/lp/public/landing/testimonials/chloe.png",
    text: "Crayo actually feels like a cheat code. I can legitimately make more videos in less time. If you want to get started clipping, you gotta give it a try.",
  },
  {
    name: "James S.",
    avatar: "https://cdn-crayo.com/lp/public/landing/testimonials/noah.png",
    text: "For real, Crayo has made clipping so much easier. I had only gone viral once before using it. Now I feel like I could go viral every time I post a new video.",
  },
];

const Testimonials = () => (
  <div className="w-full @container">
    <div className="mx-auto space-y-4 px-6 @sm:space-y-12 @2xl:max-w-7xl @2xl:space-y-16 @6xl:px-0">
      <div className="mx-auto @6xl:px-8">
        <div className="mx-auto flex flex-col items-center @6xl:max-w-5xl sm:text-center">
          <p className="text-balance text-center text-5xl font-semibold text-[#101828] @md:text-[54px]">
            See why <span className="hidden lg:inline">so many</span> people&nbsp;
            <span className="text-[#335CFF]">love Crayo</span>
          </p>
        </div>
      </div>
      <div className="mx-auto @6xl:px-6">
        <div className="mx-auto flow-root max-w-sm @2xl:max-w-none">
          <div className="mx-auto gap-5 @2xl:columns-2 @6xl:columns-3">
            {testimonials.map((data) => (
              <div
                key={data.name}
                className={cn(
                  "mt-4 overflow-hidden rounded-[20px] border-[1.7px] border-[#F3F3F3] px-2 pt-2 shadow-sm",
                  "shadow-[inset_0_3px_3px_-2px_rgba(143,143,143,0.1),inset_0_-4px_4px_-2px_rgba(143,143,143,0.1)]",
                  "@2xl:inline-block @2xl:h-64 @2xl:w-full @3xl:h-[240px] @4xl:h-[240px] @5xl:h-[220px] @6xl:h-[240px]",
                )}
              >
                <div className="space-y-4 rounded-2xl bg-white px-3 pb-5 pt-4 text-sm/6 @md:space-y-5 @md:px-4 @md:py-5">
                  <div className="flex items-center gap-x-4">
                    <div className="flex h-14 w-14 items-center justify-center">
                      <Image
                        alt="profile picture"
                        src={data.avatar}
                        className="w-full"
                        width={0}
                        height={0}
                        draggable={false}
                      />
                    </div>
                    <p className="font-medium tracking-wide text-[#272727]">{data.name}</p>
                  </div>
                  <p className="text-base/7 text-[#383838]">{data.text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Testimonials;
